// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const apiVersion = `v2`;

export const environment = {
  production: false,
  api: {
    auth: `/${apiVersion}/paymentOrder/auth`,
    details: `/${apiVersion}/paymentOrder/{id}/details`,
    validateDD: `/${apiVersion}/paymentOrder/validateDD`,
    confirm: `/${apiVersion}/paymentOrder/confirm`
  },
  // TODO: This will need to be string replaced or refactored out for another build configuration
  apiBaseUrl: 'https://api.mc-service.uat.test.monthlycare.domgentest.cloud',
  apiWithCredentials: false,
  buildConfig: '/config.json',
  addressLookupRetrieve: 'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  addressLookupFind: 'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  addressLookupKey: 'XC99-GD37-TF51-TU49',
  fallbackReturnUrl: '/monthly-care/resume'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
